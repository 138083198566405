/**
 * @author Lorenzo Cadamuro / http://lorenzocadamuro.com
 */

let LERP_SPEED = 1.2;
let TEXT_IN_SPEED = 2;
let TEXT_OUT_SPEED = 4;
let IDLE_POST_INTERACTION_DELAY = 3;
let IDLE_SPIN_SPEED = 1;
let IDLE_WOBBLE_SPEED = 0.5;

import {mat4, quat, vec3, vec2} from 'gl-matrix';
import Texture from './helpers/Texture';
import quatToEuler from './helpers/quatToEuler';
import getCamera, {projection as projectionMatrix, view as viewMatrix} from './camera';
import getCube, {Types as CubeTypes, Faces as CubeFaces, Masks as CubeMasks} from './components/cube';
import getContent, {Types as ContentTypes} from './components/content';
import getReflection from './components/reflection';
import TextManager from './TextManager.ts';

import raycast from './raycast';
import Arcball from './arcball';

const main = (assets, regl, canvas) => {
  const textManager = new TextManager(canvas.parentElement, assets);

  const rotationMatrix = mat4.create();
  const currentRotation = quat.create();
  const idleRotation = quat.create();
  let prevTime = 0;
  let time = 0;
  let lerpStartTime = time;
  const lerpStartRot = quat.create();
  let deltaTime = 0;
  let idleArcScale = 1;
  let isMouseDragging = false;
  let lastMouseDrag = -IDLE_POST_INTERACTION_DELAY;
  const idleSpeed = 0.66;
  let isFocused = true;

  const restartLerp = () => {
    lerpStartTime = time;
    quat.copy(lerpStartRot, currentRotation);
  }

  const directions = {
    innovation: [0, 0, 0], // Front face
    determination: [0, -90, 0], // Right Face
    integrity: [0, -180, 0], // Back face
    sustainability: [0, -270, 0], // Left Face
    top: [90, 0, 0],
    bottom: [-90, 0, 0],
  };

  const STATE = {
    faceSelected: false,
    hasHandledFaceSelectChange: false,
    isLerping: false,
    cameraDirection: [0, 0, 0],
    selectedName: undefined,
    lastSelectedName: undefined,
    setSelectedFace: (value) => {
      if (value === STATE.selectedName) return;
      restartLerp();
      STATE.hasHandledFaceSelectChange = false;
      STATE.lastSelectedName = value;
      STATE.selectedName = value;
      STATE.faceSelected = true;
      STATE.isLerping = false;
      STATE.cameraDirection = directions[value];
    },
    unselectFace: () => {
      STATE.hasHandledFaceSelectChange = false;
      STATE.faceSelected = false;
      STATE.isLerping = false;
      STATE.selectedName = undefined;
    }
  };

  const camera = getCamera();
  const cube = getCube();
  const content = getContent();
  const reflection = getReflection();

  const CONFIG = {
    cameraX: 0,
    cameraY: 0,
    cameraZ: 5.7,
    rotation: 4.8,
    rotateX: 1,
    rotateY: 1,
    rotateZ: 1,
    velocity: 0.005,
  };

  /**
   * Fbos
   */
  const displacementFbo = regl.framebuffer();
  const maskFbo = regl.framebuffer();
  const contentFbo = regl.framebuffer();
  const reflectionFbo = regl.framebufferCube(1024);

  /**
   * Textures
   */
  const textures = [
    {
      texture: Texture(regl, assets.find(a => a.side === 'innovation').path), // Front face, innovation
      typeId: ContentTypes.V1,
      maskId: CubeMasks.M1,
    },
    {
      texture: Texture(regl, assets.find(a => a.side === 'determination').path), // Right face, determination
      typeId: ContentTypes.V1,
      maskId: CubeMasks.M2,
    },
    {
      texture: Texture(regl, assets.find(a => a.side === 'integrity').path), // Back face, integrity
      typeId: ContentTypes.V2,
      maskId: CubeMasks.M3,
    },
    {
      texture: Texture(regl, assets.find(a => a.side === 'sustainability').path), // Left face, sustainability
      typeId: ContentTypes.V2,
      maskId: CubeMasks.M4,
    },
    {
      texture: Texture(regl, assets.find(a => a.side === 'top').path),
      typeId: ContentTypes.V3,
      maskId: CubeMasks.M5,
    },
    {
      texture: Texture(regl, assets.find(a => a.side === 'bottom').path),
      typeId: ContentTypes.V3,
      maskId: CubeMasks.M6,
    },
  ];

  const clamp = (x, min, max) => Math.max(min, Math.min(max, x));

  const valueWeights = {
      innovation: 1,
      sustainability: 1,
      integrity: 1,
      determination: 1,
  }
  const valueMovingIn = {
      innovation: false,
      sustainability: false,
      integrity: false,
      determination: false,
  }

  const animate = ({viewportWidth, viewportHeight, tick}) => {
    if (!isFocused) {
      return;
    }

    // Modify idle arc scale
    if ((isMouseDragging || STATE.faceSelected || (time < lastMouseDrag + IDLE_POST_INTERACTION_DELAY))) {
      idleArcScale = clamp(idleArcScale - deltaTime, 0, 1);
    } else {
      idleArcScale = clamp(idleArcScale + 0.5 * deltaTime, 0, 1);
    }

    const idleScale = clamp(idleArcScale, 0, 1);

    const {rotation, rotateX, rotateY, rotateZ, velocity, cameraX, cameraY, cameraZ} = CONFIG;

    displacementFbo.resize(viewportWidth, viewportHeight);
    maskFbo.resize(viewportWidth, viewportHeight);
    contentFbo.resize(viewportWidth, viewportHeight);

    /**
     * Rotation Matrix
     */
    // Calculate time and delta time
    prevTime = time;
    time = performance.now() / 1000;
    deltaTime = time - prevTime;


    // Get Arcball rotaton
    arcball.update();
    const tempRot = quat.create();
    quat.copy(tempRot, arcball.rotation);

    // Apply idle rotation
    const rotVel = quat.create();
    const faceSelectedMultiplier = STATE.faceSelected ? 0 : 1; // Disables idle animation when face selected
    quat.fromEuler(rotVel,
      idleSpeed * Math.sin(time * IDLE_WOBBLE_SPEED) * idleScale * faceSelectedMultiplier,
      idleSpeed * 0.5 * idleScale * faceSelectedMultiplier * IDLE_SPIN_SPEED,
      idleSpeed * Math.cos(time * IDLE_WOBBLE_SPEED) * idleScale * faceSelectedMultiplier
    );
    quat.mul(idleRotation, idleRotation, rotVel);

    const easeOutQuart = x => Math.sqrt(1 - Math.pow(x - 1, 2)); 
    if (STATE.faceSelected) {
      if (!STATE.hasHandledFaceSelectChange) {
        STATE.hasHandledFaceSelectChange = true;
        const currentRotQuat = quat.create();
        quat.mul(currentRotQuat, tempRot, idleRotation);
        const currentRotAxis = vec3.create();
        const currentRotAngle = quat.getAxisAngle(currentRotAxis, currentRotQuat);

        quat.setAxisAngle(idleRotation, currentRotAxis, currentRotAngle);
        quat.fromEuler(arcball.rotation, 0, 0, 0);
        
        quat.copy(tempRot, arcball.rotation);
      }
      const targetRot = quat.create();
      quat.fromEuler(targetRot, ...STATE.cameraDirection);

      const mix = clamp((time - lerpStartTime) * LERP_SPEED, 0, 1)
      quat.slerp(idleRotation, lerpStartRot, targetRot, easeOutQuart(mix));
    }

    quat.mul(currentRotation, tempRot, idleRotation);
    mat4.fromQuat(rotationMatrix, currentRotation);


    const mix = clamp((time - lerpStartTime) * LERP_SPEED, 0, 1)
    Object.keys(valueWeights).forEach(key => {
      if (key === STATE.selectedName && mix > 0.1) {
        // TODO make the movement a parameter
        valueWeights[key] = clamp(valueWeights[key] - TEXT_IN_SPEED * deltaTime, 0, 1);
        valueMovingIn[key] = true;
      } else {
        valueWeights[key] = clamp(valueWeights[key] + TEXT_OUT_SPEED * deltaTime, 0, 1);
        valueMovingIn[key] = false;
      }
    });

    /**
     * Camera config
     */
    const cameraConfig = {
      eye: [cameraX, cameraY, cameraZ],
      target: [0, 0, 0],
    };

    /**
     * Clear context
     */
    regl.clear({
      color: [0, 0, 0, 0],
      depth: 1,
    });

    camera(cameraConfig, () => {
      /**
       * Render the displacement into the displacementFbo
       * Render the mask into the displacementFbo
       */
      cube([
        {
          fbo: displacementFbo,
          cullFace: CubeFaces.BACK,
          typeId: CubeTypes.DISPLACEMENT,
          matrix: rotationMatrix,
        },
        {
          fbo: maskFbo,
          cullFace: CubeFaces.BACK,
          typeId: CubeTypes.MASK,
          matrix: rotationMatrix,
        },
      ]);

      /**
       * Render the content to print in the cube
       */
      contentFbo.use(() => {
        content({
          textures,
          displacement: displacementFbo,
          mask: maskFbo,
        });
      });
    });


    /**
     * Render the content reflection
     */
    reflection({
      reflectionFbo,
      cameraConfig,
      rotationMatrix,
      texture: contentFbo
    });

    camera(cameraConfig, () => {
      /**
       * Render the back face of the cube
       * Render the front face of the cube
       */
      cube([
        {
          cullFace: CubeFaces.FRONT,
          typeId: CubeTypes.FINAL,
          reflection: reflectionFbo,
          matrix: rotationMatrix,
        },
        {
          cullFace: CubeFaces.BACK,
          typeId: CubeTypes.FINAL,
          texture: contentFbo,
          matrix: rotationMatrix,
        },
      ]);
    });

    camera(cameraConfig, (context, props) => {
      textManager.update(valueWeights, valueMovingIn, context.view, context.projection, {viewportWidth, viewportHeight});
    });
  };

  const arcball = new Arcball(canvas);

  let isMouseDown = false;
  const startMouse = vec2.create();
  const curMouse = vec2.create();
  const handleMouseDown = (e) => {
    isMouseDown = true;
    e.preventDefault();
    if (e.touches && e.touches.length) {
      e = e.touches[0];
    }
    vec2.set(startMouse, e.clientX, e.clientY);
    vec2.copy(curMouse, startMouse);
    arcball.handleMouseDown(e.clientX, e.clientY);
  };

  const handleMouseMove = (e) => {
    e.preventDefault();
    if (isMouseDown) {
      if (e.touches && e.touches.length) {
        e = e.touches[0];
      }
      vec2.set(curMouse, e.clientX, e.clientY);
      // TODO: Make 2.0 a variable set in config
      // Only do arcball stuff if mouse is dragging
      if (!isMouseDragging && vec2.dist(startMouse, curMouse) > 2.0) {
        isMouseDragging = true;
        lastMouseDrag = time;
        STATE.unselectFace();
      }
      if (isMouseDragging) {
        arcball.handleMouseMove(e.clientX, e.clientY);
      }
    }
  };

  const handleMouseUp = (e) => {
    isMouseDown = false;
    e.preventDefault();
    if (e.touches && e.touches.length) {
      e = e.touches[0];
    }
    if (!isMouseDragging) {
      raycastCube(curMouse[0], curMouse[1], arcball.bounds);
    }
    isMouseDragging = false;
    arcball.handleMouseUp(e.clientX, e.clientY);
  };
  let eventListenersBoundElement = undefined;
  let eventListenersBound = false;
  const bindEventListeners = (el) => {
    if (eventListenersBound) throw new Error('GoodmanCube: Event listeners already bound.  Unbind event listeners first');
    eventListenersBound = true;
    eventListenersBoundElement = el;
    // Mouse evens
    el.addEventListener('mousedown', handleMouseDown);
    el.addEventListener('mousemove', handleMouseMove);
    el.addEventListener('mouseup', handleMouseUp);
    // Touch events
    el.addEventListener('touchstart', handleMouseDown);
    el.addEventListener('touchmove', handleMouseMove);
    el.addEventListener('touchend', handleMouseUp);
  }
  const unbindEventListeners = (el) => {
    if (!eventListenersBound) {
      eventListenersBound = false;
      eventListenersBoundElement = undefined;
      // Mouse evens
      el.removeEventListener('mousedown', handleMouseDown);
      el.removeEventListener('mousemove', handleMouseMove);
      el.removeEventListener('mouseup', handleMouseUp);
      // Touch events
      el.removeEventListener('touchstart', handleMouseDown);
      el.removeEventListener('touchmove', handleMouseMove);
      el.removeEventListener('touchend', handleMouseUp);
    }
  }

  const raycastCube = (mx, my, bounds) => {
    // Raycast the cube
    const it = raycast(projectionMatrix, viewMatrix, rotationMatrix, mx, my, bounds);
    // If there's an intersection, compare it against the directions object
    if (it && STATE.faceSelected === false) {
      STATE.faceSelected = true;
      if (it.direction.toString() === [0, 1, 0].toString()) {
        STATE.setSelectedFace('top');
      } else if (it.direction.toString() === [0, -1, 0].toString()) {
        STATE.setSelectedFace('bottom');
      } else if (it.direction.toString() === [1, 0, 0].toString()) {
        STATE.setSelectedFace('determination');
      } else if (it.direction.toString() === [-1, 0, 0].toString()) {
        STATE.setSelectedFace('sustainability');
      } else if (it.direction.toString() === [0, 0, -1].toString()) {
        STATE.setSelectedFace('integrity');
      } else if (it.direction.toString() === [0, 0, 1].toString()) {
        STATE.setSelectedFace('innovation');
      }
    } else {
      STATE.unselectFace();
    }
  };

  // Update loop pausing, playing
  let loopHandle = undefined;
  const play = () => {
    loopHandle = regl.frame(animate);
  }
  const stop = () => {
    if (loopHandle) {
      loopHandle.cancel();
      loopHandle = undefined;
    }
  }

  play();

  const focusSide = (side) => {
    STATE.setSelectedFace(side);
  }

  const unfocusSide = () => {
    STATE.unselectFace();
  }

  const destroy = () => {
    if (eventListenersBoundElement) unbindEventListeners(eventListenersBoundElement);
  }

  return {
    textManager,
    setFocusSpeed: v => LERP_SPEED = v,
    setTextInSpeed: v => TEXT_IN_SPEED = v,
    setTextOutSpeed: v => TEXT_OUT_SPEED = v,
    setIdleSpinSpeed: v => IDLE_SPIN_SPEED = v,
    setIdleWobbleSpeed: v => IDLE_WOBBLE_SPEED = v,
    setPostInteractionIdleDelay: v => IDLE_POST_INTERACTION_DELAY = v,
    bindEventListeners,
    unbindEventListeners,
    play,
    stop,
    focusSide,
    unfocusSide,
    destroy,
  }
} 
export default main;
