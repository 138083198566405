import {mat4, vec3} from "gl-matrix"
import {positions as cubePositions, elements as cubeElements, centers as cubeCenters} from './components/cube/config';

const vp = mat4.create();
const invVp = mat4.create();
const invViewMatrix = mat4.create();

const rayPoint = vec3.create();
const rayOrigin = vec3.create();
const rayDelta = vec3.create();
const rayDir = vec3.create();

// Below is a slightly modified version of this code:
// https://github.com/substack/ray-triangle-intersection
// It does intersection between ray and triangle.
// With the original version, we had no way of accessing 't'
// But we really needed that value.
function intersectTriangle (out: vec3, pt: vec3, dir: vec3, tri: vec3[]) {
  var EPSILON = 0.000001
  var edge1 = [0, 0, 0] as vec3
  var edge2 = [0, 0, 0] as vec3
  var tvec = [0, 0, 0] as vec3
  var pvec = [0, 0, 0] as vec3
  var qvec = [0, 0, 0] as vec3

  // @ts-ignore
  vec3.subtract(edge1, tri[1], tri[0])
  // @ts-ignore
  vec3.subtract(edge2, tri[2], tri[0])

  vec3.cross(pvec, dir, edge2)
  var det = vec3.dot(edge1, pvec)

  if (det < EPSILON) return null
  // @ts-ignore
  vec3.subtract(tvec, pt, tri[0])
  var u = vec3.dot(tvec, pvec)
  if (u < 0 || u > det) return null
  vec3.cross(qvec, tvec, edge1)
  var v = vec3.dot(dir, qvec)
  if (v < 0 || u + v > det) return null

  var t = vec3.dot(edge2, qvec) / det
  out[0] = pt[0] + t * dir[0]
  out[1] = pt[1] + t * dir[1]
  out[2] = pt[2] + t * dir[2]
  return t
}

interface RaycastModel {
  distance: number,
  vertices: vec3[],
  direction: vec3,
}

const v1 = vec3.create();
const v2 = vec3.create();
const v3 = vec3.create();
export default (projectionMatrix: mat4, viewMatrix: mat4, rotationMatrix: mat4, clientX: number, clientY: number, bounds: DOMRect): RaycastModel|null => {
  mat4.multiply(vp, projectionMatrix, viewMatrix);
  mat4.invert(invVp, vp)

  // get a single point on the camera ray.
  vec3.transformMat4(rayPoint, [2.0 * (clientX - bounds.left) / bounds.width - 1.0, -2.0 * (clientY - bounds.top) / bounds.height + 1.0, 0.0], invVp)

  // get the position of the camera.
  mat4.invert(invViewMatrix, viewMatrix)
  vec3.transformMat4(rayOrigin, [0, 0, 0], invViewMatrix)

  vec3.subtract(rayDelta, rayPoint, rayOrigin)
  vec3.normalize(rayDir, rayDelta);

  let t: number = 100000000;
  let hitTri: vec3[]|undefined;
  let hitElIndex: number|undefined;
  cubeElements.forEach((el: number[], i: number) => {
    const [el1, el2, el3] = el;
    vec3.transformMat4(v1, cubePositions[el1] as vec3, rotationMatrix);
    vec3.transformMat4(v2, cubePositions[el2] as vec3, rotationMatrix);
    vec3.transformMat4(v3, cubePositions[el3] as vec3, rotationMatrix);
    const tri = [v1, v2, v3] as vec3[];
    const res = vec3.create();
    const thisT = intersectTriangle(res, rayPoint, rayDir, tri);
    if (thisT && thisT < t) {
      t = thisT;
      hitTri = tri;
      hitElIndex = i;
    }
  })

  if (hitTri && hitElIndex !== undefined) {
    const center = cubeCenters[Math.floor(hitElIndex/2)][0];
    return {
      distance: t,
      vertices: hitTri,
      direction: center,
    }
  }
  return null;
}
