import {mat4, vec4} from "gl-matrix";
import type {IGoodmanAsset} from "../main";

type values = 'sustainability'|'integrity'|'innovation'|'determination';
const visible: Record<values, boolean> = {
  sustainability: false,
  integrity: false,
  innovation: false,
  determination: false,
}

const valuesCopy: Record<values, string> = {
  innovation: `New ideas push our business forward.  We focus on the future, proactively looking for new opportunities and improved solutions for our stakeholders that will make the world a better place for all of us.`,
  integrity: `We have integrity, always.  We work inclusively and transparently, balancing the needs of our business and our people, with the needs of the community and those we do business with.`,
  sustainability: `We're building our business for the long-term.  That's why we consider the planet and all the people on it in everything we do.  Our initiatives demonstrate our ongoing commitment to having a positive economic, environmental and social impact on the world.`,
  determination: `Determination gets things done.  We  are motivated by excellence and work hard to achieve it, actively pursuing the very best outcomes for our stakeholders.`,
};

const transformedBottomLeftPoint = vec4.create();
const transformedBottomRightPoint = vec4.create();
const vpMatrix = mat4.create();
export default class TextManager {
  parent: HTMLElement;
  copyContainer: HTMLDivElement;
  copyElements: Record<values, HTMLParagraphElement>;

  bottomLeftPoint = vec4.fromValues(-1, 1, 1, 1);
  bottomRightPoint = vec4.fromValues(1, 1, 1, 1);
  copyContainerYPos = 0;
  copyContainerXPos = 0;

  constructor(parent: HTMLElement, assets: IGoodmanAsset[]) {
    this.parent = parent;

    this.copyContainer = document.createElement('div');
    this.copyContainer.classList.add('goodmancube-copycontainer');
    this.parent.appendChild(this.copyContainer);

    // Build elements
    this.copyElements = {
      sustainability: document.createElement('p'),
      innovation: document.createElement('p'),
      integrity: document.createElement('p'),
      determination: document.createElement('p'),
    }
    Object.keys(this.copyElements).forEach((key) => {
      const k = key as values;
      const para = this.copyElements[k];
      para.classList.add('goodmancube-copy', `goodmancube-copy--${k}`, 'goodmancube-copy--entering-start', 'goodmancube-copy--exiting');
      const a = assets.find(a => a.side === k);
      console.log(a);
      if (!a) throw new Error(`GoodmanCube: 'copyPath' for ${key} not provided in config`);
      para.innerHTML = a.copyText || valuesCopy[a.side];
      this.copyContainer.appendChild(para);
    });
  }

  public update(valueWeights: Record<values, number>, movingIn: Record<values, boolean>, viewMatrix: mat4, projectMatrix: mat4, dims: { viewportWidth: number, viewportHeight: number } ) {
    movingIn;
    Object.keys(valueWeights).forEach((key) => {
      const k = key as values;
      if (valueWeights[k] < 0.01 && !visible[k]) {
        console.log(`${k} setting to visible`);
        console.log(this.copyElements[k]);
        this.copyElements[k].classList.remove('goodmancube-copy--exiting');
        this.copyElements[k].classList.add('goodmancube-copy--entering');

        // Switch easings after animation
        setTimeout(() => {
          this.copyElements[k].classList.add('goodmancube-copy--exiting-start');
          this.copyElements[k].classList.remove('goodmancube-copy--entering-start');
        }, 1000)
        visible[k] = true;
      } else if (valueWeights[k] > 0.01 && visible[k]) {
        console.log(`${k} setting to invisible`);
        this.copyElements[k].classList.remove('goodmancube-copy--entering');
        this.copyElements[k].classList.add('goodmancube-copy--exiting');

        // Switch easings after animation
        setTimeout(() => {
          this.copyElements[k].classList.add('goodmancube-copy--entering-start');
          this.copyElements[k].classList.remove('goodmancube-copy--exiting-start');
        }, 1000)
        visible[k] = false;
      }
    });

    // project point to NDC
    mat4.mul(vpMatrix, viewMatrix, projectMatrix)
    vec4.transformMat4(transformedBottomLeftPoint, this.bottomLeftPoint, vpMatrix);
    vec4.transformMat4(transformedBottomRightPoint, this.bottomRightPoint, vpMatrix);
    const { viewportHeight, viewportWidth } = dims;

    // Convert to screenspace
    transformedBottomLeftPoint[0] = transformedBottomLeftPoint[0] / transformedBottomLeftPoint[2];
    transformedBottomLeftPoint[1] = transformedBottomLeftPoint[1] / transformedBottomLeftPoint[2];
    transformedBottomLeftPoint[0] = (transformedBottomLeftPoint[0] + 1) / 2 * viewportWidth;
    transformedBottomLeftPoint[1] = (transformedBottomLeftPoint[1] + 1) / 2 * viewportHeight;

    transformedBottomRightPoint[0] = transformedBottomRightPoint[0] / transformedBottomRightPoint[2];
    transformedBottomRightPoint[1] = transformedBottomRightPoint[1] / transformedBottomRightPoint[2];
    transformedBottomRightPoint[0] = (transformedBottomRightPoint[0] + 1) / 2 * viewportWidth;
    transformedBottomRightPoint[1] = (transformedBottomRightPoint[1] + 1) / 2 * viewportHeight;

    transformedBottomLeftPoint[0] = transformedBottomLeftPoint[0] / window.devicePixelRatio;
    transformedBottomLeftPoint[1] = transformedBottomLeftPoint[1] / window.devicePixelRatio;
    transformedBottomRightPoint[0] = transformedBottomRightPoint[0] / window.devicePixelRatio;
    transformedBottomRightPoint[1] = transformedBottomRightPoint[1] / window.devicePixelRatio;

    // Apply to HTMLElement if necessary
    if (this.copyContainerYPos !== transformedBottomLeftPoint[1] || this.copyContainerXPos !== transformedBottomLeftPoint[0]) {
      this.copyContainer.style.top = `${transformedBottomLeftPoint[1]}px`;
      this.copyContainerYPos = transformedBottomLeftPoint[1];
      const width = transformedBottomRightPoint[0] - transformedBottomLeftPoint[0];
      this.copyContainer.style.width = `${width}px`;
    }
  }

  public getCopyBoundingClientRect = () => {
    const containerBounds = new DOMRect(10000, 10000, 0, 0);

    Object.keys(this.copyElements).forEach((key) => {
      const k = key as values;
      const b = this.copyElements[k].getBoundingClientRect();
      containerBounds.x = Math.min(containerBounds.x, b.x);
      containerBounds.y = Math.min(containerBounds.y, b.y);
      containerBounds.width = Math.max(containerBounds.width, b.width);
      containerBounds.height = Math.max(containerBounds.height, b.height);
    })

    return containerBounds;
  }

  destroy() {
    console.log('destroying textmanager');
  }
}
