import REGL, { InitializationOptions, Regl } from 'regl';
import main from './js/index.js';
import {setRegl} from './js/renderer';
import type TextManager from './js/TextManager';

type GoodmanValue = 'innovation'|'determination'|'integrity'|'sustainability'|'top'|'bottom'
export interface IGoodmanAsset {
  side: GoodmanValue,
  path: string,
  copyText: string,
}
interface IGoodmanCubeConfig {
  assets: IGoodmanAsset[],
  /* Whether or not to pad the bottom of the .goodmancube element to leave room for text */ addPaddingForCopy: boolean,
  enableInteractivity: boolean,
}
const defaultConfig: Omit<IGoodmanCubeConfig, 'assets'> = {
  addPaddingForCopy: true,
  enableInteractivity: true,
};
export interface IGoodmanCubeControls {
  textManager: TextManager,
  /**
   * @param v:number - Default 2
   */
  setFocusSpeed: (v:number) => void,

  /*e
   * @param v:number - Default 2
   */
  setTextInSpeed: (v:number) => void,
  /**
   * @param v:number - Default 4
   */
  setTextOutSpeed: (v:number) => void,

  /**
   * @param v:number - Default 1
   */
  setIdleSpinSpeed: (v:number) => void,
  /**
   * @param v:number - Default 0.5
   */
  setIdleWobbleSpeed: (v:number) => void,

  /**
   * @description Sets the delay (in seconds) before restarting the idle animation after dragging the canvas.
   * @param v:number - Default 3
   */
  setPostInteractionIdleDelay: (v:number) => void,
  /**
   * @description Destroys all event listeners and unbinds WebGL resources to free up memory.
   */
  destroy: () => void,

  /**
   * @description Binds event listeners to an element, enabling touch/click controls within this element. (This is run by default when GoodmanCube is constructed)
   */
  bindEventListeners: (el: HTMLElement) => void,

  /**
   * @description Unbinds event listeners, disabling touch/click controls.
   */
  unbindEventListeners: (el: HTMLElement) => void,

  play: () => void,
  stop: () => void,

  focusSide: (side: GoodmanValue) => void,
  unfocusSide: () => void,
}

/**
 * GoodmanCube interactive visualisaton
 * @name GoodmanCube
 */
export default class GoodmanCube {
  isInitialised = false;
  canvas: HTMLCanvasElement|OffscreenCanvas;
  regl?: Regl;
  config?: IGoodmanCubeControls;
  element: HTMLDivElement;
  wrapper: HTMLDivElement;
  canvasContainer: HTMLDivElement;

  /**
   * @param parent:HTMLElement - Container to initialise GoodmanCube in
   * @param config:IGoodmanCubeConfig - GoodmanCube config object
   * @throws Browser not comptable error
   * @returns
   */
  constructor(parent: HTMLElement, config: IGoodmanCubeConfig) {
    Object.assign(config, defaultConfig);
    this.element = document.createElement('div');
    this.element.classList.add('goodmancube');
    parent.appendChild(this.element);

    this.wrapper = document.createElement('div');
    this.wrapper.classList.add('goodmancube-wrapper');
    this.element.appendChild(this.wrapper);

    this.canvasContainer = document.createElement('div');
    this.canvasContainer.classList.add('goodmancube-canvascontainer');
    this.wrapper.appendChild(this.canvasContainer);
    // TODO: Add config assertions to ensure it's valid
    // Create regl renderer
    const opts: InitializationOptions = {
      container: this.canvasContainer,
      attributes: {
        antialias: true,
        alpha: true,
      },
    };
    this.regl = REGL(opts);
    this.canvas = this.regl._gl.canvas;
    setRegl(this.regl);

    this.config = main(config.assets, this.regl, this.canvas) as IGoodmanCubeControls;
    if (config.enableInteractivity) {
      this.config.bindEventListeners(this.element);
    }

    this.isInitialised = true;

    this.updateBottomPadding = this.updateBottomPadding.bind(this);
    if (config.addPaddingForCopy) {
      window.addEventListener('resize', this.updateBottomPadding);
      setTimeout(() => this.updateBottomPadding(), 100);
      setTimeout(() => this.updateBottomPadding(), 300);
      setTimeout(() => this.updateBottomPadding(), 900);
      setTimeout(() => this.updateBottomPadding(), 2700);
    }
  }

  /**
   * @description Check if browser is compatible
   */
  static isBrowserCompatible(): boolean {
    // @ts-expect-error; I.E only 
    if (window.document.documentMode) {
      return false;
    }
    return true;
  }

  /**
   * @description Continues playback of the scene.
   */
  public play() {
    if (this.config) {
      this.config.play();
    }
  }

  /**
   * @description Pauses playback of the scene.
   */
  public stop() {
    if (this.config) {
      this.config.stop();
    }
  }

  /**
   * @description Destroys all event listeners and unbinds WebGL resources to free up memory.  Wrapper of `this.config.destroy()`.
   */
  public destroy() {
    this.stop();

    window.removeEventListener('resize', this.updateBottomPadding);

    if (this.config) {
      this.config.destroy();
      this.isInitialised = false;
    }

    this.config = undefined;
    if (this.regl) {
      this.regl.destroy();
      this.regl = undefined;
    }

    if (this.element.parentElement) {
      this.element.parentElement.removeChild(this.element);
    }
  }

  /**
   * @description Go to a side of the goodman cube.
   */
  public focusSide(side: GoodmanValue) {
    if (this.config) this.config.focusSide(side);
  }

  /**
   * @description Unfocus side of cube and resume idle animation.
   */
  public unfocusSide() {
    if (this.config) this.config.unfocusSide();
  }

  /**
   * @description Calculates the correct amount of padding to add to the bottom of .goodmancube div so that it wraps the copy text
   */
  paddingBottom = 0;
  public updateBottomPadding() {
    if (this.config) {
      const b = this.config.textManager.getCopyBoundingClientRect();
      console.log('Copy bounds ', b.top, b.height, b.bottom);
      const containerBounds = this.element.getBoundingClientRect();
      const diff = b.bottom - containerBounds.bottom;
      console.log(diff)
      if (diff > 0) {
        this.element.style.paddingBottom = `${Math.floor(diff + this.paddingBottom)}px`;
      }
    }
  }
}
