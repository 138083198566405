/**
 * @author Lorenzo Cadamuro / http://lorenzocadamuro.com
 */

import {regl} from './renderer';
import {mat4, vec3} from 'gl-matrix'

const CONFIG = {
  fov: 37,
  near: 0.01,
  far: 1000,
}

const cameraConfig = {
  eye: [0, 0, 6],
  target: [0, 0, 0],
  up: [0, 1, 0],
}

export const projection = mat4.create();
export const view = mat4.create();

export default () => regl({
  context: {
    projection: ({viewportWidth, viewportHeight}) => {
      const {fov, near, far} = CONFIG
      const fovy = (fov * Math.PI) / 180
      const aspect = viewportWidth / viewportHeight

      mat4.perspective(projection, fovy, aspect, near, far)
      return projection;
    },

    view: (_context, props) => {
      const config = Object.assign({}, cameraConfig, props)

      const {eye, target, up} = config

      mat4.lookAt(view, eye as vec3, target as vec3, up as vec3)
      return view;
    },

    fov: () => {
      const {fov} = CONFIG

      return fov
    }
  },

  uniforms: {
    // @ts-ignore
    u_projection: regl.context('projection'),
    // @ts-ignore
    u_view: regl.context('view'),
    // @ts-ignore
    u_cameraPosition: regl.context('eye'),
    u_resolution: ({viewportWidth, viewportHeight}) => {
      return [viewportWidth, viewportHeight]
    },
  },
})
