import {quat, vec2, mat4} from "gl-matrix";

export default class Arcball {
  element: HTMLElement;

  mouse = vec2.create();
  lastMouse = vec2.create();
  velocity = vec2.create();
  rotation = quat.create();
  bounds: DOMRect|undefined;
  multiplier = 0;

  constructor(element: HTMLElement) {
    this.element = element;
    
  }

  public handleMouseDown(mx: number, my: number) {
    this.bounds = this.element.getBoundingClientRect();
    vec2.set(this.lastMouse, mx, my);
    this.getScreenSpace(this.lastMouse, this.lastMouse, this.bounds)
    this.multiplier = Math.min(this.bounds.width, this.bounds.height) * 3;
    this.multiplier = Math.sqrt(this.bounds.width * this.bounds.height) / Math.min(this.bounds.width, this.bounds.height) * 2.5;
  }

  public handleMouseMove(mx: number, my: number) {
    if (this.bounds) {
      vec2.set(this.mouse, mx, my);
      this.getScreenSpace(this.mouse, this.mouse, this.bounds)
      vec2.set(this.velocity,
       (this.mouse[0] - this.lastMouse[0]) * this.multiplier,
       (this.mouse[1] - this.lastMouse[1]) * this.multiplier,
      );
      vec2.copy(this.lastMouse, this.mouse);
    }
  }

  private getScreenSpace(out: vec2, v: vec2, bounds: DOMRect) {
    out[0] = (v[0] - bounds.left) / bounds.width * 2 - 1;
    out[1] = (v[1] - bounds.top) / bounds.height * 2 + 1;
  }

  public handleMouseUp(_mx: number, _my: number) {
  }

  rotationMatrix = mat4.create();
  public update() {
    const yRot = quat.create();
    quat.setAxisAngle(yRot, [0, 1, 0], this.velocity[0]);
    const xRot = quat.create();
    quat.setAxisAngle(xRot, [1, 0, 0], this.velocity[1]);

    vec2.scale(this.velocity, this.velocity, 0.85);

    const q1 = quat.create();
    quat.mul(q1, yRot, xRot);
    quat.mul(this.rotation, q1, this.rotation);
  }
}
